var DocumentModalChooser = function () {
    return {
        modal: null,
        textinput: null,
        searchinput: null,
        imageholder: null,
        loadedimages: null,
        selectedimage: null,
        pagination: 1,
        num_loaded: 0,
        num_total: 0,
        jstree: null,
        current_dir: null,
        current_search_function: null,
        init: function (modal) {


            this.setModal(modal);
            this.setTextInput();
            this.bindSelectImage();
            this.bindSearch();
            this.bindImageSelection();
            this.bindLoadMoreBtn();
            this.bindUpload();

            DocumentFilesTree.init(this)

            this.addUploader();
        },
        setModal: function (modal) {
            this.modal = modal;
            this.searchinput = modal.find('input.searchinput');
            this.imageholder = modal.find('.modal-images-holder');
        },
        setTextInput: function () {
            this.textinput = ModalTextInput.getInput()
//            id = this.modal.attr('id');
//            this.textinput = $('input[data-modalid=' + id + ']');
        },
        bindSelectImage: function () {
            fileModalChooser = this;
            $('.js-choose-image').on('click', function () {
//                    fileModalChooser.textinput.val('Value...');

                fileModalChooser.modal.modal('hide');
                fileModalChooser.populateSelectedImageData();

            })
        },
        bindSearch: function () {
            button = this.modal.find('.btn-search-images');

            fileModalChooser = this;

//                this.searchinput.keyup(function (e) {
//                    e.preventDefault();
//                    if (e.keyCode == 13)
//                    {
//                        e.preventDefault();
//                        button.trigger('click');
//                    }
//                });

            this.searchinput.onEnter(function (e) {
                e.preventDefault();
                button.trigger('click');
            });

            button.on('click', function () {
                fileModalChooser.setSearchFunction(fileModalChooser.performTermSearch);
                fileModalChooser.fetchImages(true)
//                fileModalChooser.pagination = 1;
//                fileModalChooser.resetData();
//                fileModalChooser.performTermSearch();
            })

            //button.on('click',)
        },
        setSearchFunction: function (func) {
            this.current_search_function = func;
        },
        fetchImages: function (reset_data) {
            if (typeof reset_data !== 'undefined' && reset_data === true) {
                fileModalChooser.pagination = 1;
                fileModalChooser.resetData();
            }
            this.modal.find('.ajax-loader').show();
            fileModalChooser.current_search_function();
        },
        resetData: function () {
            this.removeImages();
            this.num_loaded = 0;
            this.num_total = 0;
            $('.num-loaded').html('-')
            $('.num-total').html('-')
        },
        performTermSearch: function () {
            var term = fileModalChooser.searchinput.val();

            var url = this.modal.data('targeturl');
            form_data = {
                'search-criteria': term,
                'page': fileModalChooser.pagination,
            }

            $.ajax({
                url: url,
                method: 'GET',
                data: form_data,
                success: function (msg) {

                    fileModalChooser.modal.find('.ajax-loader').hide();

                    fileModalChooser.loadedimages = msg.result;
                    // fileModalChooser.removeImages();

                    fileModalChooser.handleResults(msg);
//                    if (msg.result.length > 0) {
//                        fileModalChooser.createImages(msg.result);
//                        fileModalChooser.setNumStatus(msg.result.length, msg.total);
//                        fileModalChooser.pagination++;
//                        if (msg.last_page) {
//                            $('#load_results_btn').hide();
//                        } else {
//                            $('#load_results_btn').show();
//                        }
//                    } else {
//                        fileModalChooser.setNotFountMessage();
//                    }

                },
                error: function (msg) {
                    fileModalChooser.modal.find('.ajax-loader').hide();
                    fileModalChooser.imageholder.html('<strong>' + ReportMessages.message('error_searching_file') + '</strong>')

                }
            });
        },
        performDirFetch: function () {

            var fileModalChooser = this;
            var url = this.modal.find('.jstree').data('dirsearch')

            form_data = {
                'search-dir': this.current_dir,
                'page': fileModalChooser.pagination,
            }


            $.ajax({
                url: url,
                method: 'GET',
                data: form_data,
                success: function (msg) {

                    fileModalChooser.modal.find('.ajax-loader').hide();

                    fileModalChooser.loadedimages = msg.result;

                    fileModalChooser.handleResults(msg);
                    fileModalChooser.jstree.createNewNodes(msg.subdirectories);
                },
                error: function (msg) {
                    fileModalChooser.modal.find('.ajax-loader').hide();
                    fileModalChooser.imageholder.html('<strong>' + ReportMessages.message('error_searching_file') + '</strong>')

                }
            });

        },
        handleResults: function (server_response) {

            if (server_response.result.length > 0) {
                this.removeImages();
                this.createFilesPreview(server_response.result);

            } else {
                this.setNotFountMessage();
            }
        },
        prependUploadedResults: function (server_response) {
            if (server_response.result.length > 0) {

                //     fileModalChooser.loadedimages = server_response.result;

                this.createUploadedFilesPreview(server_response.result)
            }
        },
        removeImages: function () {
            this.imageholder.html('');
        },
        createFilesPreview: function (images) {
            var fileModalChooser = this;

            $.each(images, function (key, item) {

                preview = fileModalChooser.createFilePreview(key, item);
                fileModalChooser.imageholder.append(preview);
            });
            this.bindDeleteFile()
        },
        createUploadedFilesPreview: function (images) {
            var fileModalChooser = this;
            fileModalChooser.imageholder.prepend('<div class="col-xs-12 clearfix" style="border-top: 1px solid #ddd;"></div>');

            var last_datakey = fileModalChooser.loadedimages.length;

            $.each(images, function (key, item) {
                fileModalChooser.loadedimages.push(item);
                preview = fileModalChooser.createFilePreview(last_datakey + key, item);
                fileModalChooser.imageholder.prepend(preview);
            });
            this.bindDeleteFile()

        },
        createFilePreview: function (key, image) {
            return $(image.display);
//            var parent_div = $('<div/>', {
//                class: 'col-sm-1 modal-image-wrapper modal-file-wrapper',
//                'data-imagekey': key,
//            });
//
//            var img = $('<img/>', {
//                src: image.filetype_icon,
//            }).appendTo(parent_div);
//
//            var p_info = $('<p/>', {
//                text: image.filename.substring(0, 40)
//            }).appendTo(parent_div);
//
//            return parent_div;

        },
        bindImageSelection: function () {
            fileModalChooser = this;
            this.modal.on('click', '.modal-image-wrapper>img', function () {
                fileModalChooser.setSelectedImage($(this).closest('.modal-image-wrapper').data('path'))
                $('.modal-image-wrapper').removeClass('bg-blue');
                $(this).closest('.modal-image-wrapper').addClass('bg-blue');

            })

            this.modal.on('dblclick', '.modal-image-wrapper>img', function () {
                fileModalChooser.setSelectedImage($(this).closest('.modal-image-wrapper').data('path'))
                $('.modal-image-wrapper').removeClass('bg-blue');
                $(this).closest('.modal-image-wrapper').addClass('bg-blue');

                fileModalChooser.modal.modal('hide');
                fileModalChooser.populateSelectedImageData();

            })


        },
        bindLoadMoreBtn: function () {
            fileModalChooser = this;
            $('#load_results_btn').on('click', function () {
                // fileModalChooser.performTermSearch();
                fileModalChooser.fetchImages()
            })
        },
        setSelectedImage: function (path) {
            module = this;

            $.each(this.loadedimages, function (key, item) {

                if (path === item.path) {
                    module.selectedimage = item

                }
            })

            $('#filename_preview').html(path)

        },
        populateSelectedImageData: function () {

            image = this.selectedimage;

            if (image === null) {
                return;
            }



            this.textinput.val(this.constructSelectedFilename());
            this.textinput.trigger('change')

//            img_preview_holder = this.textinput.closest('.article-image-holder').find('.news-image-preview');
//
//            img_preview_holder.find('.img-preview').css({display: 'block', 'background-image': 'url(' + image.thumbs[2].uri + ')'});
//
//            img_preview_holder.find('.img-preview').find('img').attr('src', image.thumbs[2].uri);
//            img_preview_holder.find('.img-preview').show();
//            img_preview_holder.find('.img-fallback').hide();
//            img_preview_holder.find('.img-name').text(image.name);
//            img_preview_holder.find('.img-author').text(image.author);
//            img_preview_holder.find('.img-year').text(image.year);
//            img_preview_holder.find('#image_signature').text(image.description);
        },
        constructSelectedFilename: function () {
            image_url = this.selectedimage.path;

            return image_url;
        },
        setNumStatus: function (results_count, total_count) {
            this.num_loaded += results_count;
            this.num_total = total_count;

            $(".num-loaded").html(this.num_loaded);
            $(".num-total").html(this.num_total);
        },
        setNotFountMessage: function () {
            this.imageholder.html('<strong>' + ReportMessages.message('error_chooser_no_files_found') + '</strong>');
        },
        bindUpload: function () {
            $('.js-upload-image-btn').on('click', function () {
                $('.image_select_holder').hide();
                $('.image_upload_holder').fadeIn();
                $('.js-upload-image-btn').prop('disabled', true);
                $('.js-back').prop('disabled', false).addClass("yellow");
            })

            $('.js-back').on('click', function () {
                $('.image_upload_holder').hide();
                $('.image_select_holder').fadeIn();
                $('.js-upload-image-btn').prop('disabled', false);
                $('.js-back').prop('disabled', true).removeClass("yellow");
            })
        },
        setCurrentDirInfo: function (path) {
            $('.js-chosen-directory').html(path);
            this.modal.find('.destination-input').val(path)
        },
        addUploader: function () {
            var doc_input = this.modal.find('.js-files-uploader');
            var doc_preview = this.modal.find('.js-imagespreview');
            DocumentsUploader.init(doc_input, doc_preview);
            DocumentsUploader.setModalChooser(this)
        },
        bindDeleteFile: function () {

            $('.js-btn-file-delete').on('click', function (e) {
                e.stopPropagation();
            })

            $('.js-btn-file-delete').confirmation({
                onConfirm: function (e) {
                    e.preventDefault();

                    var file_path = $(this).attr('path')
                    file_holder = $('.modal-file-wrapper[data-path="' + file_path + '"]')



                    $.ajax({
                        url: '/uprava/filechooser/delete',
                        method: 'POST',
                        data: {'file_url': file_path},
                        success: function (msg) {
                            console.log(msg)
                            if (msg.success === true) {
                                toastr.success('Successfuly deleted.')
                                file_holder.slideUp(200)
                            } else {
                                toastr.error('Error occcured during deleting.')
                            }
                        },
                        error: function (msg) {
                            console.log(msg)
                            toastr.error('Error occcured during deleting.')

                        }
                    });
                }
            });
        }

    }
}();


var SearchImages = function () {
    return{
        search: function () {

        }
    }
}();