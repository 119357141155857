var DocumentFilesTree = function () {
    return {
        holder: null,
        fileModalChooser: null,
        init: function (fileModalChooser) {
            this.fileModalChooser = fileModalChooser;
            fileModalChooser.jstree = this;
            this.holder = fileModalChooser.modal.find('.file_structure')
            this.createTree();
            this.bindClickSearch();
        },
        createTree: function () { 
       
            filestree = this;
            var holder = this.holder;
            
            this.holder.jstree({
                "core": {
                    'check_callback': true,
                    "themes": {
                        "responsive": false
                    }
                },
                "types": {
                    "default": {
                        "icon": "fa fa-folder icon-state-warning icon-lg"
                    },
                    "file": {
                        "icon": "fa fa-file icon-state-warning icon-lg"
                    }
                },
                "state": {"key": "demo2"},
                "plugins": ["types", "contextmenu", "state"],
                "contextmenu": {
                    "items": function ($node) {


                        var tmp = $.jstree.defaults.contextmenu.items();
                        delete tmp.create.action;
                        tmp.create.label = "New";
                        tmp.create.action = function (data) {
                            var inst = $.jstree.reference(data.reference),
                                    obj = inst.get_node(data.reference);
                            inst.create_node(obj, {}, "last", function (new_node) {
                                new_node.data = {file: true};
                                setTimeout(function () {
                                    inst.edit(new_node);
                                }, 0);
                            });
                        };
                        if (this.get_type(node) === "file") {
                            delete tmp.create;
                        }
                        return tmp;

                    }
                }
            }).bind("create.jstree", function (e, data) {

//        $.ajax({
//            url: "@Url.Action("create", "employees")", 
//            type: 'POST',
//            data: {
//                "name" : data.rslt.name
//            },
//            success: function (result) {
//            }
//        });
            })
//                    .on('rename_node.jstree', function (e, data) {
//
//                filestree.renameDirectoryCall(filestree.getDirectoryPath($('#' + data.node.id)), data.text, data.old);
//            });
        },
        bindClickSearch: function () {
            var filestree = this;
            this.holder.on('select_node.jstree', function (e, data) {
                // var link = $('#' + data.selected).find('a');
                var link = $('#' + data.selected).find('a.jstree-clicked');
             
                var search_path = filestree.getDirectoryPath(link)

                filestree.fileModalChooser.setSearchFunction(filestree.fileModalChooser.performDirFetch)
                filestree.fileModalChooser.current_dir = search_path;
            
                
                filestree.fileModalChooser.fetchImages(true)
                filestree.fileModalChooser.setCurrentDirInfo(search_path);
//                if (link.attr("href") != "#" && link.attr("href") != "javascript:;" && link.attr("href") != "") {
//                    if (link.attr("target") == "_blank") {
//                        link.attr("href").target = "_blank";
//                    }
//                    document.location.href = link.attr("href");
//                    return false;
//                }
            });
        },
        getDirectoryPath: function (directory_link) {
            if (!directory_link) {
                directory_link = this.holder.find('a.jstree-clicked');
            }


            var this_dir_name = '';
            if (directory_link.closest('li').attr('id') !== 'j1_1') {
                this_dir_name = directory_link.text().trim();
            }

            if (directory_link.closest('ul').length > 0 && directory_link.closest('ul').parent('li').length > 0) {
                var parent_a_link = directory_link.closest('ul').parent('li').children('a');
                return this.getDirectoryPath(parent_a_link) + '/' + this_dir_name;
            } else {

                return this_dir_name;
            }
        },
        renameDirectoryCall: function (path, new_name, old_name) {
            var url = this.holder.data('dirrename');
         
            form_data = {
                'root_path': path,
                'old_name': old_name,
                'new_name': new_name
            }
        
           // return;
            $.ajax({
                url: url,
                method: 'POST',
                data: form_data,
                success: function (msg) {
                  
                },
                error: function (msg) {
                  
                }
            });
        },
        createNewNodes: function (nodes) {
//         
            if(nodes.length > 0){
            var parent = '#' + this.holder.jstree().get_selected()[0];
            var current_children = this.holder.jstree().get_children_dom(parent)
           
            var curr = current_children.map(function () {
                return $(this).find('a').text().replace(/^\s+|\s+$/g, '')
            }).toArray()
            
            for (var key in nodes) {
                node = nodes[key];
                    
                if ($.inArray(node.dirname, curr) === -1) {
                
                    this.holder.jstree().create_node(parent, {text: node.dirname})
                }
            }

            this.holder.jstree().open_node(parent)
        }

        }
    }
}();