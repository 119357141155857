var VitoSorter = function () {

    return {
        init: function () {
            var sorter_obj = this;
            sorters = $('.vt-sorter');

            sorters.each(function (index) {

                var sorter = $(this);
                holder = sorter.closest('.vt-sorter-holder')
                sorter.nestable({
                    group: index,
                    maxDepth: 1,
                });

                holder.find('.vt-sort-btn').on('click', function () {
                    var progress = holder.find('.vt-sorting-progress')
                    progress.show();

                    var form_data = {
                        'sort_data': window.JSON.stringify(sorter.nestable('serialize')),
                    }

                    console.log("Nestable serialized data:")
                    console.log(form_data)

                    var target = sorter.data('target');
                    sorter_obj.sendRequest(target, form_data, progress)
                })

            });

//            $('.vt-sorter').nestable({
//                group: 1
//            });

//            function getOutputE() {
//                return window.JSON.stringify($('#slides_list').nestable('serialize'));
//            }

//            $('#sort_slides').on('click', function () {
//                progress.show();
//
//                var form_data = {
//                    'sort_data': getOutputE(),
//                }
//
//                var target = $("#slides_list").data('target');
//
//                $.ajax({
//                    url: target,
//                    type: 'POST',
//                    data: form_data,
//                    success: function (msg) {
//                        console.log(msg);
//                        if (msg.success === true) {
//                            progress.hide();
//                            toastr.success(msg.message, "");
//                        } else {
//                            progress.hide();
//                            toastr.error(msg.message, "");
//                        }
//                    },
//                    error: function () {
//                        progress.hide();
//                        toastr.error("Došlo je do greške pri sortiranju.", "");
//                    }
//                });
//            })
        },
        sendRequest: function (url, form_data, progress_holder) {

            $.ajax({
                url: url,
                type: 'POST',
                data: form_data,
                success: function (msg) {
                    console.log(msg);
                    if (msg.success === true) {
                        progress_holder.hide();
                        toastr.success(msg.message, "");
                    } else {
                        progress_holder.hide();
                        toastr.error(msg.message, "");
                    }
                },
                error: function () {
                    progress_holder.hide();
                    toastr.error(ReportMessages.message('sorting_error'), "");
                }
            });
        }
    };

}();

VitoSorter.init();