
jQuery(document).ready(function () {
    Metronic.init(); // init metronic core componets
    Layout.init(); // init layout
    QuickSidebar.init(); // init quick sidebar
    Demo.init(); // init demo features
    Index.init();
//    Index.initDashboardDaterange();
//    Index.initJQVMAP(); // init index page's custom scripts
//    Index.initCalendar(); // init index page's custom scripts
//    Index.initCharts(); // init index page's custom scripts
//    Index.initChat();
//    Index.initMiniCharts();
    Tasks.initDashboardWidget();  

           ComponentsDropdowns.init();
           
           
            

});