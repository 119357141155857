function bytesToSize(bytes) {
   var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
   if (bytes == 0) return '0 Byte';
   var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
   return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
};

var DocumentsUploader = function () {
    return {
        file_input: null,
        files_container: null,
        modalChooser: null,
        init: function (file_input, files_container) {
           
            this.file_input = $(file_input);
            this.files_container = $(files_container);
            this.bindFilesSelect();
            this.bindSubmitImages();
        },
        setModalChooser: function(chooser){
          this.modalChooser = chooser;  
        },
        bindFilesSelect: function () {
                       
            var filesuploader = this;
            this.file_input.on('change', function (event) {
                event.preventDefault();
                var files = event.originalEvent.target.files;
                var max_filesize = 30; //size in MB

                var increment = 0;

                //remove previous images previews
                $('.upload-preview-holder').remove()
                // Loop through the FileList and render image files as thumbnails.

                var clear = false;
                for (var i = 0; i < files.length; i++) {


                    var f = files[i];
console.log('Chosen file')
                    if (f.size > max_filesize * 1024 * 1024) {
                        console.log('FAILED SIZE')
                        toastr.error(ReportMessages.message('chooser_file_too_large')+": " + f.name + " (" + parseInt(f.size / 1024) + " KB)", "");
                        clear = true;
                        break;
                    }


                    $('.upload_submit_holder').show();
                    var reader = new FileReader();

                    // Closure to capture the file information.
                    reader.onload = (function (theFile) {
                        return function (e) {
                            var uploaded_filename = theFile.name;
console.log(theFile)
                            element_to_append = filesuploader.createElement(uploaded_filename, theFile.size)
                            filesuploader.files_container.append(element_to_append);

                            increment += 1;
                            return;
                        };


                    })(f);

                    // Read in the image file as a data URL.
                    reader.readAsDataURL(f);
                }


                if (clear === true) {
                    setTimeout(function () {
                        control = filesuploader.file_input;
                        control.replaceWith(control.val('').clone(true));
                        $('.upload-preview-holder').remove()
                    }, 500)


                } else {
                    setTimeout(function () {
                        $('.upload-preview-holder').show();
                    }, 500)

                }

            });
        },
        createElement: function (filename, size) {
            var re = /(?:\.([^.]+))?$/;
            var extension = re.exec(filename)[1];
            
            
            element = jQuery('<div/>', {
                class: 'col-md-3 upload-preview-holder upload-file-preview-holder',
                style: 'display: none;'
            });

            img_holder = jQuery('<img/>', {
                class: 'img-filetype',
                src: '/public/common/filetype-icons/48px/' + extension.toLowerCase() + '.png'
            }).appendTo(element);

            span_imgname = jQuery('<span/>', {
                text: filename,
                class: 'filename',
            }).appendTo(element);
            
            
             span_imgname = jQuery('<p/>', {
                text: ' ('+bytesToSize(size)+')',
                class: 'filename',
            }).appendTo(element);



            return element;

        },
        uploadXHR: function () {
            var filesuploader = this;
            var form = $(this.file_input).closest('form');
            var url = form.attr('action');
            var data = new FormData(form[0]);
            var type = form.attr('method');
         
            $.ajax({
                url: url,
                type: 'POST',
                data: data,
                cache: false,
                contentType: false,
                processData: false,
                // Custom XMLHttpRequest
                xhr: function () {
                    var myXhr = $.ajaxSettings.xhr();
                    if (myXhr.upload) {
                        // For handling the progress of the upload
                        myXhr.upload.addEventListener('progress', function (e) {
                            if (e.lengthComputable) {
                                filesuploader.showing_upload_progress(e.loaded, e.total);
                            }
                        }, false);
                    }
                    return myXhr;
                },
                success: function (response) {
                    if (response.success === true) {
                        toastr.success(response.message)
                         filesuploader.reset_upload_form()
                        $('.js-back').trigger('click');
                         filesuploader.modalChooser.prependUploadedResults(response)
                        
//                       form.closest('.modal').find('a.jstree-clicked').trigger('click')
                    } else {
                        toastr.error(response.message);
                    }
                },
                complete: function(){
                    filesuploader.reset_upload_progress()
                    
                }
            });
        },
        showing_upload_progress: function (loaded, total) {
            console.log(loaded, total)
            
           
            var progress_holder = this.file_input.closest('form').find('.upload_progress');
            progress_holder.show();
            
            percentage = Math.round((loaded/total)*100, 1);
            
            
            progress_holder.find('.percentage-value').html(percentage+'%')
            progress_holder.find('.progress-bar').attr('aria-valuenow', percentage)
            progress_holder.find('.progress-bar').css({ width: percentage+'%' });
            
        },
        reset_upload_progress: function(){
               var progress_holder = this.file_input.closest('form').find('.upload_progress');
         
            progress_holder.find('.percentage-value').html('0%')
            progress_holder.find('.progress-bar').attr('aria-valuenow', 0)
            progress_holder.find('.progress-bar').css({ width: '0%' });
               progress_holder.hide();
            
       
        },
        reset_upload_form: function(){
               var upl_form = this.file_input.closest('form');
               upl_form.find('.js-imagespreview').html('');
               upl_form.find('.upload_submit_holder').hide();
        },
        bindSubmitImages: function () {

            var filesreader = this;
            submit_button = this.file_input.closest('form').find('.upload_submit_holder').find('.js-submit');


          
            
           

            submit_button.on('click', function(){
                $(this).closest('form').submit();
            })
       
            submit_button.closest('form').on('submit', function (e) {
                e.preventDefault();
                filesreader.uploadXHR();


            })

            
            
            
            
            
            
            
            
            
            
            
            
            
            
        }
    }
}();