var FilesUploader = function () {
    return {
        file_input: null,
        images_container: null,
        modalChooser: null,
        init: function (file_input, images_container) {
            this.file_input = $(file_input);
          
            this.images_container = $(images_container);
            this.bindImagesSelect();
            this.bindSubmitImages();
        },
        setModalChooser: function (chooser) {
            this.modalChooser = chooser;
        },
        bindImagesSelect: function () {           
            var filesuploader = this;
            this.file_input.on('change', function (event) {
                event.preventDefault();
                var files = event.originalEvent.target.files;
                var max_filesize = 3; //size in MB
                var allowed_extensions = ['image/jpg', 'image/jpeg', 'image/png'];
                var increment = 0;

               
                //remove previous images previews
                $('.upload-preview-holder').remove()
                // Loop through the FileList and render image files as thumbnails.

                var clear = false;
                for (var i = 0; i < files.length; i++) {


                    var f = files[i];

                    if (allowed_extensions.indexOf(f.type) === -1) {
                        toastr.error(ReportMessages.message('chooser_wrong_file_type')+": " + f.name, "");
                        clear = true;
                        break;
                    } else if (f.size > max_filesize * 1024 * 1024) {
                        toastr.error(ReportMessages.message('chooser_file_too_large')+": " + f.name + " (" + parseInt(f.size / 1024) + " KB)", "");
                        clear = true;
                        break;
                    }


                    $('.upload_submit_holder').show();
                    var reader = new FileReader();

                    // Closure to capture the file information.
                    reader.onload = (function (theFile) {
                        return function (e) {
                            var uploaded_filename = theFile.name;

                            element_to_append = filesuploader.createElement(uploaded_filename, increment, e.target.result)
                            filesuploader.images_container.append(element_to_append);

                            increment += 1;
                            return;
                        };


                    })(f);

                    // Read in the image file as a data URL.
                    reader.readAsDataURL(f);
                }


                if (clear === true) {
                    setTimeout(function () {
                        control = filesuploader.file_input;
                        control.replaceWith(control.val('').clone(true));
                        $('.upload-preview-holder').remove()
                    }, 500)


                } else {
                    setTimeout(function () {
                        $('.upload-preview-holder').show();
                    }, 500)

                }

            });
        },
        createElement: function (filename, index, imgsrc) {
           
            inputs = ImageInputs.getInputs();
            element = jQuery('<div/>', {
                class: 'col-md-3 upload-preview-holder',
                style: 'display: none;'
            });

            img_holder = jQuery('<div/>', {
                class: 'img-holder',
                style: 'background: url(' + imgsrc + ')'
            }).appendTo(element);

            span_imgname = jQuery('<span/>', {
                text: filename,
                class: 'col-md-12 no-padding label bg-purple-studio',
            }).appendTo(element);

            for (var key in inputs) {
                inputname = inputs[key];

                jQuery('<input/>', {
                    class: 'form-control col-sm-12',
                    type: 'text',
                    style: 'margin-top: 5px;',
                    name: 'files[' + md5(filename) + '][' + inputname + ']',
                    placeholder: key
                }).appendTo(span_imgname);
            }

            return element;

        },
        uploadXHR: function () {
            var filesuploader = this;
           var form = $(this.file_input).closest('form');
            var url = form.attr('action');
            var data = new FormData(form[0]);
            var type = form.attr('method');
            
            $.ajax({
                url: url,
                type: 'POST',
                data: data,
                cache: false,
                contentType: false,
                processData: false,
                // Custom XMLHttpRequest
                xhr: function () {
                    var myXhr = $.ajaxSettings.xhr();
                    if (myXhr.upload) {
                        // For handling the progress of the upload
                        myXhr.upload.addEventListener('progress', function (e) {
                            if (e.lengthComputable) {
                                filesuploader.showing_upload_progress(e.loaded, e.total);
                            }
                        }, false);
                    }
                    return myXhr;
                },
                success: function (response) {
                    if (response.success === true) {
                        toastr.success(response.message)
                           filesuploader.reset_upload_form()
                        $('.js-back').trigger('click');
                        filesuploader.modalChooser.prependUploadedResults(response)
//                       form.closest('.modal').find('a.jstree-clicked').trigger('click')
                    } else {
                        toastr.error(response.message);
                    }
                },
                complete: function(){
                    filesuploader.reset_upload_progress()
                    
                }
            });
        },
        showing_upload_progress: function (loaded, total) {
       
            
           
            var progress_holder = this.file_input.closest('form').find('.upload_progress');
            progress_holder.show();
            
            percentage = Math.round((loaded/total)*100, 1);
            
            
            progress_holder.find('.percentage-value').html(percentage+'%')
            progress_holder.find('.progress-bar').attr('aria-valuenow', percentage)
            progress_holder.find('.progress-bar').css({ width: percentage+'%' });
            
        },
        reset_upload_progress: function(){
               var progress_holder = this.file_input.closest('form').find('.upload_progress');
         
            progress_holder.find('.percentage-value').html('0%')
            progress_holder.find('.progress-bar').attr('aria-valuenow', 0)
            progress_holder.find('.progress-bar').css({ width: '0%' });
               progress_holder.hide();
            
       
        },
        reset_upload_form: function(){
               var upl_form = this.file_input.closest('form');
               upl_form.find('.js-imagespreview').html('');
               upl_form.find('.upload_submit_holder').hide();
        },
        bindSubmitImages: function () {

            var filesreader = this;
            submit_button = this.file_input.closest('form').find('.upload_submit_holder').find('.js-submit-img');


            submit_button.on('click', function(){
                $(this).closest('form').submit();
            })
       
            submit_button.closest('form').on('submit', function (e) {
                e.preventDefault();
                filesreader.uploadXHR();


            })

        }
    }
}();