var IntervalChooser = function () {
    return {
        chooser: null, //chooser portlet
        init: function (chooser) {
            this.chooser = $(chooser)

            this.bindCreateTr();
            this.bindClose();
            this.bindSave();
            this.bindDelete();
        },
        bindCreateTr: function () {
            $('body').on('click', '.vt-edit-interval-btn', function () {
                $(this).closest('tr').next('tr').slideDown();
            })
        },
        bindClose: function () {
            this.chooser.on('click', '.vt-close-tr', function (e) {
                e.preventDefault();
                $(this).closest('tr').hide();
            });
        },
        bindSave: function () {
            $('body').on('submit', '.vt-interval-form', function (e) {
                e.preventDefault();
                var form = $(this);
                var tr = $(this).closest('tr');
                var target_url = $(this).attr('action');
                var method = $(this).attr('method');
                var form_data = $(this).serialize();
                tr.find('.loader').show();

                $.ajax({
                    url: target_url,
                    method: method,
                    data: form_data,
                    success: function (response) {
                        if (response.success === true) {

                            if (response.action === 'insert') {
                                $(response.tr).insertBefore(form.closest('table').find('.vt-new-row'))
                            }

                            if (response.action === 'edit') {

                                tr.prev('tr').replaceWith($(response.tr))
                                tr.prev('tr').remove(); //remove new form
                            
                            }
 VitoGlobal.initDateTimeInputs();
                            toastr.success(response.message);
                        } else {
                            toastr.error(response.message);
                        }

                    },
                    error: function (response) {
                        toastr.error(ReportMessages.message('interval_error'));
                    }
                })


            })

            // this.chooser.on('click', '.vt-save-interval-btn',)
        },
        bindDelete: function () {


            $('body').on('submit', '.vt-delete-interval-form', function (e) {
                e.preventDefault();

                var tr = $(this).closest('tr');
                var target_url = $(this).attr('action');
                var method = $(this).attr('method');
                var form_data = $(this).serialize();


                $.ajax({
                    url: target_url,
                    method: method,
                    data: form_data,
                    success: function (response) {
                        if (response.success === true) {



                            toastr.success(response.message);
                            tr.remove();
                        }

                    },
                    error: function (response) {
                        toastr.error(ReportMessages.message('interval_error'));
                    }
                })

            });
        }
    }
}();