var GridSorter = function (){
    return {
        init: function () {
            var el = document.getElementById('sort-images');
            Sortable.create(el, {
                animation: 150,
                draggable: '.draggable',
                handle: '.item-handle'
            });

            $('#grid_sort').on('click', function () {
                $('#sorting-progress').show();

                var items = $('#sort-images').find('li').map(function () {
                    return {'id': $(this).data('id')};
                }).get();

                var form_data = {
                    'sort_data': window.JSON.stringify(items),
                }

                var target = $("#sort-images").data('target');
         
                $.ajax({
                    url: target,
                    type: 'POST',
                    data: form_data,
                    success: function (msg) {
                        console.log(msg);
                        if (msg.success === true) {
                            $('#sorting-progress').hide();
                            toastr.success(msg.message, "");
                        } else {
                            $('#sorting-progress').hide();
                            toastr.error(msg.message, "");
                        }
                    },
                    error: function () {
                        $('#sorting-progress').hide();
                        toastr.error("Došlo je do greške pri sortiranju.", "");
                    }
                });
            })
        }
    }
}();